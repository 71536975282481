
import { CopyOutlined, EyeInvisibleOutlined, EyeTwoTone, LeftOutlined } from '@ant-design/icons';
import { Button as AntButton, Breadcrumb, Col, Form, Image, Input, Modal, Row, Select, Spin, Switch, Tabs, UploadFile, message } from 'antd';
import { set } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import Box from '../../components/Box';
import Button from '../../components/Button';
import BreadcrumbItemStyled from '../../components/FormItem/BreadcrumbItemStyled';
import FormItemNoMarginStyled from '../../components/FormItem/FormItemNoMarginStyled';
import { copyClipboard } from '../../helpers/FunctionUtils';
import accountApis, { IApiUpdateSettingTwoFactorBody, IUpdateSettingConfigItem } from '../../services/accountApis';
import { setLoading } from '../../store/loading/actions';
import { setShopState } from '../../store/shop/actions';
import { PageStyled } from '../styles';
import { TextLinkStyled } from './components/SalonItem';
import SelectDevices from './components/SelectDevices';
import UpdateBanner from './components/UpdateBanner';
import UploadGallery from './components/UploadGallery';
import { initialAccountDevices } from './components/constants';
import PhoneInput from '../../components/PhoneInput';

const { Option } = Select;

export const SalonInfoPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const _location = useLocation();

    const [tabDefaulf, setTabDefaulf] = useState<any>('SALON_INFORMATION');
    const onChange = (key: string) => {
        setTabDefaulf(key);
    };
    const { id } = useParams();
    const [shopDate, setShopDate] = useState<any>(null);

    useEffect(() => {
        const initData = async () => {
            try {
                if (id && !shopDate) {
                    const resShopById = await accountApis.getShopById(id);
                    if (resShopById.status === 200) {
                        setShopDate(resShopById.data.data);
                        dispatch(setShopState(resShopById.data.data));
                    }
                }
                const tabFromHash = window.location.hash.substring(1);
                if (tabFromHash) {
                    onChange(tabFromHash);
                }
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            }
        };
        initData();
    }, []);

    return (
        <PageStyled>
            <Box className="d-between">
                <Breadcrumb style={{ marginBottom: '10px' }}>
                    <BreadcrumbItemStyled>Salon {shopDate?.shopName}</BreadcrumbItemStyled>
                </Breadcrumb>
                <Box display="flex" gap={1}>
                    <Button onClick={() => navigate(_location.state?.path || '/accounts')}>
                        <LeftOutlined />
                        Back
                    </Button>
                </Box>
            </Box>
            <ContentStyled>
                <Tabs
                    className='tab-credit-card'
                    tabPosition={'left'}
                    items={[
                        {
                            label: 'Basic Information',
                            key: '1',
                            children: <SALON_INFORMATION />,
                        },
                        {
                            label: 'Reset Password',
                            key: '2',
                            children: <SALON_RESET_PASSWORD />,
                        },
                        {
                            label: 'Verify 2-factor',
                            key: '6',
                            children: <VERIFY_2_FACTOR />,
                        },
                        {
                            label: 'Gallery',
                            key: '3',
                            children: <SALON_GALLERY />,
                        },
                        {
                            label: 'About Us',
                            key: '4',
                            children: <SALON_ABOUT_US />,
                        },
                        {
                            label: 'Setting',
                            key: '5',
                            children: <SETTING />,
                        },
                    ]}
                />
            </ContentStyled>
        </PageStyled>
    );
};

export const SALON_INFORMATION = () => {
    const dispatch = useDispatch();
    const [formUpdateSalon] = Form.useForm();
    const [listDevice, setListDevice] = useState<any>(null);
    const [lstTimezone, setLstTimezone] = useState<any[]>([]);
    const [salonData, setSalonData] = useState<any>(null);
    const watchDevices = Form.useWatch('devices', formUpdateSalon);
    const { id } = useParams();

    const onReset = () => {
        if (!salonData) return;
        const updatedDevices = initialAccountDevices.map(device => {
            const feature = salonData.deviceFeatures.find((f: any) => f.feature === device.feature);
            if (feature) {
                return {
                    ...device,
                    numberDevice: feature.numberDevice,
                    enable: true,
                    id: feature.id,
                    shopId: feature.shopId
                };
            }
            return device;
        });
        setListDevice(updatedDevices);
        formUpdateSalon.setFieldsValue({
            shopId: salonData.id,
            shopName: salonData.shopName,
            address: salonData.address,
            email: salonData.email,
            phone: salonData.phone,
            zip: salonData.zip,
            city: salonData.city,
            state: salonData.state,
            bookingOnline: salonData.bookingLink ? true : false,
            giftOnline: salonData.giftOnlineLink ? true : false,
            devices: updatedDevices,
            timeZone: salonData.timeZone,
            bannerUrl: salonData.bannerUrl,
        });
    };

    const initData = async () => {
        try {
            dispatch(setLoading(true));
            const resTimeZone = await accountApis.getTimeZone();
            if (resTimeZone.status === 200) {
                setLstTimezone(resTimeZone.data.data);
            }
            const response = await accountApis.getShopById(id);
            if (response.status === 200) {
                const data = response.data.data;
                const updatedDevices = initialAccountDevices.map(device => {
                    const feature = data.deviceFeatures.find((f: any) => f.feature === device.feature);
                    if (feature) {
                        return {
                            ...device,
                            numberDevice: feature.numberDevice,
                            enable: true,
                            id: feature.id,
                            shopId: feature.shopId
                        };
                    }
                    return device;
                });
                setListDevice(updatedDevices);
                setSalonData(data);
                formUpdateSalon.setFieldsValue({
                    shopId: data.id,
                    shopName: data.shopName,
                    address: data.address,
                    email: data.email,
                    phone: data.phone,
                    zip: data.zip,
                    city: data.city,
                    state: data.state,
                    bookingOnline: data.bookingLink ? true : false,
                    giftOnline: data.giftOnlineLink ? true : false,
                    devices: updatedDevices,
                    timeZone: data.timeZone,
                    bannerUrl: data.bannerUrl,
                });
            } else {
                message.error({ content: response.data.message as string, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: 'System error: ' + error.message as string, duration: 3 });
        } finally {
            dispatch(setLoading(false));
        }
    };
    useEffect(() => {
        initData();
    }, []);

    const onSubmitUpdateSalon = async (values: any) => {
        try {
            dispatch(setLoading(true));
            const deviceFeatures = values?.devices?.filter((item: any) => item.enable)?.map((item: any) => ({
                id: item.id,
                feature: item.feature,
                shopId: item.shopId,
                numberDevice: Number(item.numberDevice) || 0,
            }));
            const response = await accountApis.updateSalon(
                values.shopId, values.shopName, values.timeZone, values.phone, values.email, values.address,
                values.city, values.state, values.zip, deviceFeatures, values.giftOnline, values.bookingOnline
            );
            if (response.status === 200) {
                message.success({ content: 'Update Salon success' as string, duration: 2 });
            } else {
                message.error({ content: response.data.message as string, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 3 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleUpdateBanner = async (_value: any) => {
        if (!salonData) return;
        const formData = new FormData();
        formData.append('file', _value);
        try {
            dispatch(setLoading(true));
            const response = await accountApis.updateSalonBanner(salonData.id, formData);
            if (response.status === 200) {
                message.success({ content: 'Update Salon banner success' as string, duration: 2 });
                initData();
            } else {
                message.error({ content: response.data.message as string, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 3 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <ContentCreditStyled>
            <Form
                form={formUpdateSalon}
                layout="vertical"
                onFinish={onSubmitUpdateSalon}
                initialValues={{ devices: listDevice }}>
                <FormItemNoMarginStyled name="shopId" style={{ display: 'none' }}>
                    <Input type='hidden' />
                </FormItemNoMarginStyled>
                <Box>
                    <Row>
                        <span className='title-tab'>Basic Information</span>
                    </Row>
                    <Row justify={'center'}>
                        <FormItemNoMarginStyled name="bannerUrl" noStyle>
                            <UpdateBanner onSubmit={handleUpdateBanner} />
                        </FormItemNoMarginStyled>
                    </Row>
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <FormItemNoMarginStyled name="shopName" label="Salon’s Name"
                                rules={
                                    [
                                        { required: true }
                                    ]
                                }
                            >
                                <Input size='large' autoComplete='off' />
                            </FormItemNoMarginStyled>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <FormItemNoMarginStyled name="address" label="address"
                                rules={
                                    [
                                        { required: true }
                                    ]
                                }>
                                <Input size='large' autoComplete='off' />
                            </FormItemNoMarginStyled>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <FormItemNoMarginStyled name="email" label="Email" rules={
                                [
                                    { required: true },
                                    {
                                        type: 'email',
                                        message: 'The input is not valid email',
                                    }
                                ]
                            }>
                                <Input size='large' autoComplete='off' placeholder='Please enter...' />
                            </FormItemNoMarginStyled>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <FormItemNoMarginStyled
                                name="phone"
                                label="Phone"
                                rules={
                                    [
                                        { required: true }
                                    ]
                                }>
                                <Input size='large' type="number" autoComplete='off' placeholder='Please enter...' />
                            </FormItemNoMarginStyled>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <FormItemNoMarginStyled name="zip" label="Zip Code" rules={
                                [
                                    { required: true }
                                ]
                            }>
                                <Input size='large' autoComplete='off' placeholder='Please enter...' />
                            </FormItemNoMarginStyled>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <FormItemNoMarginStyled name="city" label="City" rules={
                                [
                                    { required: true }
                                ]
                            }>
                                <Input size='large' autoComplete='off' placeholder='Please enter...' />
                            </FormItemNoMarginStyled>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <FormItemNoMarginStyled name="state" label="State" rules={
                                [
                                    { required: true }
                                ]
                            }>
                                <Input size='large' autoComplete='off' placeholder='Please enter...' />
                            </FormItemNoMarginStyled>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <SelectDevices watchDevices={watchDevices} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <FormItemNoMarginStyled name="timeZone" label="Timezone"
                                rules={
                                    [
                                        { required: true }
                                    ]
                                }>
                                <Select size='large' showSearch placeholder="Select">
                                    {lstTimezone?.map((tz, index) => (
                                        <Option key={index} value={tz.value}>
                                            {tz.name}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItemNoMarginStyled>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Row>
                                <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                    <FormItemCheckBoxStyled label=" ">
                                        {salonData?.bookingLink ?
                                            (
                                                <TextLinkStyled variant="CONTENT_1" color="blue"
                                                    onClick={() => { copyClipboard(salonData.bookingLink); }}>
                                                    <span style={{ marginRight: '5px' }}>Booking Online Link</span>
                                                    <CopyOutlined />
                                                </TextLinkStyled>
                                            ) : null}
                                    </FormItemCheckBoxStyled>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                    <FormItemCheckBoxStyled label=" ">
                                        {salonData?.giftOnlineLink ?
                                            (
                                                <TextLinkStyled variant="CONTENT_1" color="blue"
                                                    onClick={() => { copyClipboard(salonData.giftOnlineLink); }}>
                                                    <span style={{ marginRight: '5px' }}>Gift Card Online Link</span>
                                                    <CopyOutlined />
                                                </TextLinkStyled>
                                            ) : null}
                                    </FormItemCheckBoxStyled>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Box>
                <Box style={{ textAlign: 'right' }} className='footer-actions'>
                    <button type='button' className='btn-reset' onClick={onReset}>
                        <span>Reset</span>
                    </button>
                    <button type='button' className='btn-submit' onClick={() => formUpdateSalon.submit()}>
                        <span>Save</span>
                    </button>
                </Box>
            </Form>

        </ContentCreditStyled>
    );
};

export const SALON_RESET_PASSWORD = () => {
    const dispatch = useDispatch();
    const [formResetPin] = Form.useForm();
    const { id } = useParams();

    const onSubmit = async (values: any) => {
        try {
            const result = await Swal.fire({
                title: 'Confirm reset password',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes, Reset it',
                confirmButtonColor: '#d42b3c',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
            });
            if (result.isConfirmed) {
                dispatch(setLoading(true));
                const response = await accountApis.resetPwdShop(id, values.newPwd);
                if (response.status === 200) {
                    formResetPin.resetFields();
                    message.success({ content: 'Reset password success' as string, duration: 2 });
                } else {
                    message.error({ content: response.data.message as string, duration: 2 });
                }
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <ContentCreditStyled>
            <Form form={formResetPin}
                layout="vertical" style={{ marginTop: '10px' }}
                onFinish={onSubmit}>
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item
                            name="newPwd"
                            label="New Password"
                            style={{ paddingRight: '10px' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                                {
                                    min: 6,
                                    message: 'Password must be at least 6 characters long',
                                },
                            ]}>
                            <Input.Password
                                size="large"
                                placeholder="New PIN"
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item
                            name="confirmNewPassword"
                            label="Confirm New Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input confirm password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPwd') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('Do not match');
                                    },
                                }),
                            ]}>
                            <Input.Password
                                size="large"
                                placeholder="Confirm Owner PIN"
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Box style={{ textAlign: 'right' }} className='footer-actions'>
                    <button type='button' className='btn-submit' onClick={() => formResetPin.submit()}>
                        <span>Save</span>
                    </button>
                </Box>
            </Form>
        </ContentCreditStyled>
    );
};

interface ISalonGalleryItem {
    updatedDate: Date;
    updatedBy: null;
    createdDate: Date;
    createdBy: string;
    id: string;
    shopId: string;
    urlImage: string;
    status: boolean;
}

export const SALON_GALLERY = () => {
    const dispatch = useDispatch();
    const [formResetPin] = Form.useForm();
    const { id = '' } = useParams();
    const [images, setImages] = useState<ISalonGalleryItem[]>([]);
    const [modal, contextHolder] = Modal.useModal();

    const initData = async () => {
        try {
            dispatch(setLoading(true));

            const response = await accountApis.getSalonListGallery(id);
            if (response.status === 200) {
                const data = response.data.data;
                setImages(data);
            } else {
                message.error({ content: response.data.message as string, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: 'System error: ' + error.message as string, duration: 3 });
        } finally {
            dispatch(setLoading(false));
        }
    };
    useEffect(() => {
        initData();
    }, []);

    const handleUploadGallery = async (_values: UploadFile[]) => {
        if (!id) return;
        const formData = new FormData();
        _values.map(o => {
            formData.append('files', o.originFileObj as any);
        });
        try {
            dispatch(setLoading(true));
            const response = await accountApis.addNewSalonGallery(id, formData);
            if (response.status === 200) {
                message.success({ content: 'Update Salon gallery success' as string, duration: 2 });
                initData();
            } else {
                message.error({ content: response.data.message as string, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 3 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleDeleteSalonGallery = async (galleryId: string) => {
        const confirmed = await modal.confirm({ title: 'Are you sure?' });
        if (!confirmed) return;
        try {
            dispatch(setLoading(true));
            const response = await accountApis.deleteSalonGallery(galleryId);
            if (response.status === 200) {
                message.success({ content: 'delete success' as string, duration: 2 });
                initData();
            } else {
                message.error({ content: response.data.message as string, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 3 });
        } finally {
            dispatch(setLoading(false));
        }
    };


    return (
        <ContentCreditStyled className='gallery-kit'>
            <Row align={'middle'} style={{ gap: 16 }}><h1 style={{ flex: 1, alignSelf: 'flex-start' }}>Gallery ({images.length})</h1>
                <Row>
                    <AntButton size='large' style={{ display: 'flex', alignItems: 'center', borderRadius: 4, backgroundColor: '#E3E9ED' }} type='text' icon={<RemoveIcon fill='#8E9BA9' />}> Delete All</AntButton>
                </Row>
                <Row>
                    <AntButton size='large' style={{ display: 'flex', alignItems: 'center', borderRadius: 4 }} type='primary' ghost icon={<RemoveIcon fill='#008BFF' />}> Select To Delete</AntButton>
                </Row>
                <UploadGallery onSubmit={handleUploadGallery} />
            </Row>

            <div className='gallery-list'>
                {images.map(o => (
                    <div key={o.id} className='gallery-item'>
                        <Image src={o.urlImage} style={{ width: '100%', height: '100%' }} />
                        <Button ghost variant='PRIMARY' className="btn-remove" onClick={() => handleDeleteSalonGallery(o.id)}>
                            <RemoveIcon />
                        </Button>
                    </div>
                ))}
            </div>
            {contextHolder}
        </ContentCreditStyled>
    );
};

interface ISalonAboutUsResData {
    updatedDate: Date;
    updatedBy: null;
    createdDate: Date;
    createdBy: string;
    id: string;
    shopId: string;
    workingTime: null;
    description: null;
    categoryNote: null;
}

export const SALON_ABOUT_US = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { id = '' } = useParams();
    const [detailData, setDetailData] = useState<any>(null);
    const onReset = () => form.setFieldsValue(detailData);

    const initData = async () => {
        try {
            dispatch(setLoading(true));

            const response = await accountApis.getSalonListAboutUs(id);
            if (response.status === 200) {
                const data = response.data.data;
                if (data) {
                    setDetailData(data);
                    form.setFieldsValue(data);
                }
            } else {
                message.error({ content: response.data.message as string, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: 'System error: ' + error.message as string, duration: 3 });
        } finally {
            dispatch(setLoading(false));
        }
    };
    useEffect(() => {
        initData();
    }, []);

    const handleFinish = async (values: ISalonAboutUsResData) => {
        try {
            dispatch(setLoading(true));
            const payload = { aboutUsId: values.id, description: values.description || '', workingTime: values.workingTime || '', categoryNote: values.categoryNote || '' }
            const response = await accountApis.updateSalonAboutUs(id, payload);
            if (response.status === 200) {
                const data = response.data.data;
                if (data) {
                    const res = await accountApis.getSalonListAboutUs(id);
                    form.setFieldsValue(res.data.data);
                }
            } else {
                message.error({ content: response.data.message as string, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: 'System error: ' + error.message as string, duration: 3 });
        } finally {
            dispatch(setLoading(false));
        }
    };


    return (
        <ContentCreditStyled className='gallery-kit'>
            <Row align={'top'}><h1 style={{ flex: 1 }}>About Us</h1>
            </Row>
            <Form layout='vertical' form={form} onFinish={handleFinish}>
                <Row style={{ gap: 16 }}>
                    <Form.Item style={{ flex: 1 }} label='Working Time' name={'workingTime'}>
                        <Input.TextArea placeholder='Please enter...' rows={4} />
                    </Form.Item>
                    <Form.Item style={{ flex: 1 }} label='Category Note' name={'categoryNote'}>
                        <Input.TextArea placeholder='Please enter...' rows={4} />
                    </Form.Item>
                </Row>
                <Form.Item label='Description' name={'description'}>
                    <Input.TextArea placeholder='Please enter...' rows={4} />
                </Form.Item>
            </Form>
            <Box style={{ textAlign: 'right' }} className='footer-actions'>
                <button type='button' className='btn-reset' onClick={onReset}>
                    <span>Reset</span>
                </button>
                <button type='button' className='btn-submit' onClick={() => form.submit()}>
                    <span>Save</span>
                </button>
            </Box>
        </ContentCreditStyled>
    );
};

const ContentStyled = styled.div`
    background: rgba(255, 255, 255, 1);
    margin-top: 2rem;
    padding: 1rem;

    .tab-credit-card .ant-tabs-tab .ant-tabs-tab-btn {
        font-size: 18px;
    }

    .tab-credit-card .ant-tabs-nav {
        background: rgba(246, 247, 252, 1);
        width: 235px;
        min-height: 500px;
    }

    .tab-credit-card .ant-tabs-tab-active {
        background: rgba(255, 136, 144, 1);
    }

    .tab-credit-card .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #ffffff;
    }

    .custom-th .ant-table-thead >tr>th {
        background: rgba(227, 233, 237, 1)
    }

    .tab-marketing-child .ant-tabs-tab {
        min-width: 120px !important;
    }

    .tab-marketing-child .ant-tabs-tab .ant-tabs-tab-btn {
        line-height: 1;
    }

    .tab-marketing-child .ant-tabs-tab-active {
        background: rgba(255, 136, 144, 1);
        min-width: 150px;
        padding: 8px 24px;
        text-align: center;
    }

    .tab-marketing-child .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #ffffff;
    }

    && .btn-delete, .btn-edit {
        background: transparent;
        border: none;
        padding: 8px;
      }
    
    && .btn-delete:hover {
        background: rgba(245, 118, 127, 1) !important;
        border: none !important;
    }

    && .coupon-label-status {
        border-radius: 2px;
        color: #1d2129;
        padding: 5px 20px 5px 20px;
    }
`;

const ContentCreditStyled = styled(Box)`
    &.gallery-kit {
        min-height: 40vh;
        .gallery-list {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
        }
        .gallery-item {
            position: relative;
            width: 200px;
            height: 200px;
            border: 1px solid #C9CDD4;
            border-radius: 8px;
            .ant-image {
                .ant-image-img {
                    width: 200px;
                    height: 200px;
                    border-radius: 8px;
                }
            }
            .btn-remove {
                position: absolute;
                right: 8px; bottom: 8px;
                background-color: #F53F3F;
                height: 40px;
                width: 40px;
                border-radius: 40px;
                border: none;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }


    .ant-form-item-label label {
        color: #1D2129;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .footer-actions {
        display: flex;
        align-items: center;
        gap: 16px;
        justify-content: flex-end;
        button {
            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }
        .btn-reset {
            display: flex;
            width: 170px;
            height: 40px;
            padding: 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: 2px;
            border: 1px solid #86909C;
            background: #E3E9ED;
            span {
                color: #566A7F;
                text-align: center;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .btn-submit {
            display: flex;
            height: 40px;
            width: 170px;
            padding: 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            border: 1px solid #008BFF;
            background: #008BFF;
            span {
                color: #FFF;
                text-align: center;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
`;

const FormItemCheckBoxStyled = styled(Form.Item)`
  margin: 10px;
  && .ant-form-item-label label {
    color: transparent;
  }
`;

const RemoveIcon = ({ fill }: { fill?: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="22" viewBox="0 0 17 22" fill="none">
        <path d="M4.7292 1.71252C4.72964 1.22945 5.16757 0.805401 5.64934 0.815384C7.5161 0.81495 9.38285 0.81278 11.2492 0.816686C11.7192 0.824065 12.1315 1.24247 12.1333 1.71252C12.1381 2.33926 12.1329 2.96599 12.1359 3.59273C13.362 3.59229 14.5881 3.59273 15.8138 3.59229C16.1242 3.58318 16.4323 3.73986 16.6046 3.99854C16.803 4.28413 16.8169 4.68344 16.6394 4.98249C16.4749 5.27285 16.1446 5.45471 15.8112 5.44429C10.8915 5.44429 5.97139 5.44429 1.05169 5.44429C0.778684 5.45167 0.506114 5.33448 0.327729 5.12745C0.105941 4.87962 0.0377983 4.50809 0.155854 4.19776C0.256982 3.91738 0.502642 3.69516 0.791705 3.62224C0.933198 3.58361 1.0812 3.59316 1.2266 3.59229C2.39327 3.59273 3.56037 3.59229 4.72703 3.59273C4.72964 2.96599 4.72486 2.33926 4.7292 1.71252ZM6.57946 2.66695C6.5812 2.97597 6.57686 3.28457 6.58163 3.59316C7.81558 3.59143 9.04908 3.59273 10.2826 3.59273C10.2843 3.28413 10.2826 2.97554 10.2835 2.66738C9.04865 2.66608 7.81427 2.66608 6.57946 2.66695Z" fill={fill || "white"} />
        <path d="M1.6332 6.42569C2.0707 6.25555 2.60846 6.48255 2.79292 6.91354C2.88537 7.10408 2.87799 7.31979 2.87582 7.52638C2.87582 10.884 2.87582 14.2421 2.87582 17.5997C2.88189 18.0108 3.04075 18.417 3.31245 18.7252C3.63537 19.0967 4.11801 19.3272 4.61193 19.3328C7.14361 19.3337 9.6753 19.3328 12.207 19.3337C12.674 19.338 13.1384 19.1457 13.4674 18.8146C13.7877 18.496 13.9804 18.0516 13.9865 17.5993C13.9874 14.1692 13.9865 10.7386 13.9874 7.3085C13.9809 6.9973 14.1436 6.69001 14.4058 6.52204C14.6618 6.35017 15.0056 6.32282 15.2864 6.44913C15.5842 6.57716 15.8007 6.87317 15.8324 7.19565C15.8428 7.32065 15.8381 7.44609 15.8389 7.57152C15.8389 10.8411 15.8385 14.1106 15.8389 17.3806C15.8949 18.5238 15.3815 19.6779 14.4813 20.3875C13.8554 20.8944 13.0551 21.18 12.2495 21.1848C9.70308 21.1852 7.15663 21.1852 4.60976 21.1848C3.72434 21.18 2.84847 20.8306 2.1983 20.2304C1.4661 19.565 1.02686 18.5893 1.02426 17.5989C1.02382 14.1687 1.02382 10.7382 1.02426 7.30763C1.01341 6.92308 1.27122 6.55373 1.6332 6.42569Z" fill={fill || "white"} />
        <path d="M6.36602 8.2468C6.65595 8.17649 6.97713 8.25722 7.19761 8.45904C7.399 8.63656 7.51185 8.90479 7.50534 9.17302C7.50577 11.3167 7.50577 13.4603 7.50577 15.604C7.51489 15.9252 7.34562 16.2442 7.07088 16.4126C6.76402 16.611 6.33911 16.5992 6.04397 16.3835C5.79397 16.2095 5.64466 15.9065 5.65378 15.6027C5.65464 13.4452 5.65204 11.2876 5.65508 9.13048C5.65378 8.71859 5.96497 8.33534 6.36602 8.2468Z" fill={fill || "white"} />
        <path d="M10.0582 8.24941C10.3472 8.17563 10.6701 8.25245 10.8928 8.45123C11.0998 8.62918 11.2157 8.90175 11.2092 9.17346C11.2092 11.3149 11.2092 13.4564 11.2092 15.5979C11.2183 15.8931 11.0803 16.1882 10.8433 16.3653C10.6068 16.5485 10.2791 16.6031 9.99566 16.5085C9.70008 16.4139 9.46137 16.1609 9.38715 15.8597C9.34852 15.7182 9.35807 15.5702 9.3572 15.4252C9.3585 13.3271 9.3559 11.2294 9.3585 9.13136C9.3572 8.72337 9.66189 8.34229 10.0582 8.24941Z" fill={fill || "white"} />
    </svg>
)

const settingConfigs = {
    'TICKET/PRINT_VOIDED': {
        name: 'TICKET/PRINT_VOIDED', key: 'PRINT_VOIDED', type: 'TICKET',
        label: 'Active Print Voided Ticket',
    },
    'CHECK_IN/PRINT_AFTER_CHECK_IN': {
        name: 'CHECK_IN/PRINT_AFTER_CHECK_IN', key: 'PRINT_AFTER_CHECK_IN', type: 'CHECK_IN',
        label: 'Print After Check In',
    },
    'CHECK_IN/SHOW_HIDE_PRICE': {
        name: 'CHECK_IN/SHOW_HIDE_PRICE', key: 'SHOW_HIDE_PRICE', type: 'CHECK_IN',
        label: 'Show Price ',
    },
    'CHECK_IN/SHOW_POINT_CUSTOMER': {
        name: 'CHECK_IN/SHOW_POINT_CUSTOMER', key: 'SHOW_POINT_CUSTOMER', type: 'CHECK_IN',
        label: 'Show Point Customer',
    },
    'CHECK_IN/SKIP_CHOOSE_STAFF': {
        name: 'CHECK_IN/SKIP_CHOOSE_STAFF', key: 'SKIP_CHOOSE_STAFF', type: 'CHECK_IN',
        label: 'Skip choose Staff',
    },
    'CHECK_IN/VOICE_NOTIFICATION_STAFF': {
        name: 'CHECK_IN/VOICE_NOTIFICATION_STAFF', key: 'VOICE_NOTIFICATION_STAFF', type: 'CHECK_IN',
        label: 'Voice call staff',
    },
    'CHECK_IN/SKIP_CHOOSE_SERVICE_CHECK_IN': {
        name: 'CHECK_IN/SKIP_CHOOSE_SERVICE_CHECK_IN', key: 'SKIP_CHOOSE_SERVICE_CHECK_IN', type: 'CHECK_IN',
        label: 'Skip choose service',
    },
    'CHECK_IN/SKIP_CHOOSE_APP_WALK_IN': {
        name: 'CHECK_IN/SKIP_CHOOSE_APP_WALK_IN', key: 'SKIP_CHOOSE_APP_WALK_IN', type: 'CHECK_IN',
        label: 'Skip modal show walk/Appt',
    },
    'APPOINTMENT/APPOINTMENT_REMINDER': {
        name: 'APPOINTMENT/APPOINTMENT_REMINDER', key: 'APPOINTMENT_REMINDER', type: 'APPOINTMENT',
        label: 'Send Appointment Reminder',
    },
    'APPOINTMENT/SEND_PUSH_NOTIFICATION': {
        name: 'APPOINTMENT/SEND_PUSH_NOTIFICATION', key: 'SEND_PUSH_NOTIFICATION', type: 'APPOINTMENT',
        label: 'Push notification',
    },
    'PAYMENT/MULTIPLE_PAY_CARD': {
        name: 'PAYMENT/MULTIPLE_PAY_CARD', key: 'MULTIPLE_PAY_CARD', type: 'PAYMENT',
        label: 'Multiple Pay Card',
    },
    'PAYMENT/MODAL_TIP': {
        name: 'PAYMENT/MODAL_TIP', key: 'MODAL_TIP', type: 'PAYMENT',
        label: 'Show Modal Tip',
    },
    'UI/VERSION': {
        name: 'UI/VERSION', key: 'VERSION', type: 'UI',
        label: 'version UI',
    },
    'PAYMENT/SHOW_UI_STAFF_CASHIER': {
        name: 'PAYMENT/SHOW_UI_STAFF_CASHIER', key: 'SHOW_UI_STAFF_CASHIER', type: 'PAYMENT',
        label: 'show choose staff cashier',
    },
    'PAYMENT/AUTO_SHOW_ADD_PRICE': {
        name: 'PAYMENT/AUTO_SHOW_ADD_PRICE', key: 'AUTO_SHOW_ADD_PRICE', type: 'PAYMENT',
        label: 'enable auto show modal add price cashier',
    },
    'PAYMENT/MULTIPLE_PRINT': {
        name: 'PAYMENT/MULTIPLE_PRINT', key: 'MULTIPLE_PRINT', type: 'PAYMENT',
        label: 'double print after payment',
    },
    'TURN/TURN_MANUAL': {
        name: 'TURN/TURN_MANUAL', key: 'TURN_MANUAL', type: 'TURN',
        label: 'on off turn manual',
    },
    'TURN/SYNC_BILL_MANUAL_TURN': {
        name: 'TURN/SYNC_BILL_MANUAL_TURN', key: 'SYNC_BILL_MANUAL_TURN', type: 'TURN',
        label: 'turn manual and auto',
    },
};

const settingConfigArray = Object.values(settingConfigs);

export interface ISettingConfigItem {
    updatedDate: null;
    updatedBy: null;
    createdDate: Date;
    createdBy: null;
    id: string;
    shopId: string;
    type: string;
    key: string;
    enable: boolean;
    value: string;
}

export const SETTING = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { id = '' } = useParams();
    const [loading, setLoadingForm] = useState(false);
    const [initialValues, setInitialValues] = useState<string>('');
    const onReset = () => form.setFieldsValue(initialValues);

    const initData = async () => {
        try {
            dispatch(setLoading(true));
            const res = await accountApis.getSettingConfigs(id);
            const data = res?.data?.data as ISettingConfigItem[];
            if (data) {
                const objectValues: any = {};
                settingConfigArray.map(s => {
                    const item = data.find(o => o.type === s.type && o.key === s.key);
                    if (item) {
                        set(objectValues, [s.name], item);
                    } else {
                        set(objectValues, [s.name], { id: null, enable: false, key: s.key, type: s.type, shopId: id });
                    }
                })
                setInitialValues(Object.entries(objectValues || {}).map(([name, value]: [name: string, value: any]) => `${name}/${value?.enable}`).join(','));
                form.setFieldsValue(objectValues);
            } else {
                message.error({ content: res.data.message as string, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: 'System error: ' + error.message as string, duration: 3 });
        } finally {
            dispatch(setLoading(false));
        }
    };
    useEffect(() => {
        initData();
    }, []);

    const handleFinish = async (values: any) => {
        if (!id) return;
        setLoadingForm(true);
        const configs: IUpdateSettingConfigItem[] = Object.values(values)?.map((o: any) => ({ enable: o.enable, id: o.id, key: o.key, type: o.type, value: o.value || '' })) || [];
        try {
            const res = await accountApis.updateSettingConfigs(id, configs);
            if (res?.data?.data) {
                await initData();
            } else {
                message.error({ content: res.data.message as string, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: 'System error: ' + error.message as string, duration: 3 });
        } finally {
            setLoadingForm(false);
        }
    };

    return (
        <ContentCreditStyled className='gallery-kit'>
            <Row align={'top'}><h1 style={{ flex: 1 }}>Setting</h1>
            </Row>
            <Spin size='large' spinning={loading}>
                <SettingContainer layout='vertical' form={form} onFinish={handleFinish}>
                    <div className="card-configs">
                        <div className="card-config">
                            <div className="card-header">
                                <span className="card-header-title">Print</span>
                            </div>
                            <div className="card-body">
                                <SettingConfigFormItem config={settingConfigs['TICKET/PRINT_VOIDED']} />
                                <SettingConfigFormItem config={settingConfigs['CHECK_IN/PRINT_AFTER_CHECK_IN']} />
                            </div>
                        </div>
                        <div className="card-config">
                            <div className="card-header">
                                <span className="card-header-title">Check In</span>
                            </div>
                            <div className="card-body">
                                <SettingConfigFormItem config={settingConfigs['CHECK_IN/SHOW_HIDE_PRICE']} />
                                <SettingConfigFormItem config={settingConfigs['CHECK_IN/SHOW_POINT_CUSTOMER']} />
                                <SettingConfigFormItem config={settingConfigs['CHECK_IN/SKIP_CHOOSE_STAFF']} />
                            </div>
                            <div className="card-body">
                                <SettingConfigFormItem config={settingConfigs['CHECK_IN/VOICE_NOTIFICATION_STAFF']} />
                                <SettingConfigFormItem config={settingConfigs['CHECK_IN/SKIP_CHOOSE_SERVICE_CHECK_IN']} />
                                <SettingConfigFormItem config={settingConfigs['CHECK_IN/SKIP_CHOOSE_APP_WALK_IN']} />
                            </div>
                        </div>
                        <div className="card-config">
                            <div className="card-header">
                                <span className="card-header-title">Appointment</span>
                            </div>
                            <div className="card-body">
                                <SettingConfigFormItem config={settingConfigs['APPOINTMENT/APPOINTMENT_REMINDER']} />
                                <SettingConfigFormItem config={settingConfigs['APPOINTMENT/SEND_PUSH_NOTIFICATION']} />
                            </div>
                        </div>
                        <div className="card-config">
                            <div className="card-header">
                                <span className="card-header-title">Payment</span>
                            </div>
                            <div className="card-body">
                                <SettingConfigFormItem config={settingConfigs['PAYMENT/MULTIPLE_PAY_CARD']} />
                                <SettingConfigFormItem config={settingConfigs['PAYMENT/MODAL_TIP']} />
                                <SettingConfigFormItem config={settingConfigs['PAYMENT/SHOW_UI_STAFF_CASHIER']} />
                            </div>
                            <div className="card-body">
                                <SettingConfigFormItem config={settingConfigs['PAYMENT/AUTO_SHOW_ADD_PRICE']} />
                                <SettingConfigFormItem config={settingConfigs['PAYMENT/MULTIPLE_PRINT']} />
                            </div>
                        </div>
                        <div className="card-config">
                            <div className="card-header">
                                <span className="card-header-title">UI</span>
                            </div>
                            <div className="card-body">
                                <SettingConfigFormItem config={settingConfigs['UI/VERSION']} />
                            </div>
                        </div>
                        <div className="card-config">
                            <div className="card-header">
                                <span className="card-header-title">Setting Turn</span>
                            </div>
                            <div className="card-body">
                                <SettingConfigFormItem config={settingConfigs['TURN/TURN_MANUAL']} />
                                <SettingConfigFormItem config={settingConfigs['TURN/SYNC_BILL_MANUAL_TURN']} />
                            </div>
                        </div>
                    </div>
                    <Form.Item shouldUpdate noStyle>
                        {({ getFieldsValue }) => {
                            const values = getFieldsValue();
                            const valueStr = Object.entries(values || {}).map(([name, value]: [name: string, value: any]) => `${name}/${value?.enable}`).join(',');
                            return (
                                <Box style={{ textAlign: 'right' }} className='footer-actions'>
                                    <button type='button' className='btn-reset' onClick={onReset}>
                                        <span>Reset</span>
                                    </button>
                                    <button disabled={initialValues === valueStr} type='button' className='btn-submit' onClick={() => form.submit()}>
                                        <span>Save</span>
                                    </button>
                                </Box>
                            )
                        }}
                    </Form.Item>
                </SettingContainer>
            </Spin>
        </ContentCreditStyled>
    );
};

const SettingContainer = styled(Form)`
.btn-submit {
    &:disabled {
        opacity: 0.6;
    }
}
.card-configs {
    gap: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.card-config {
    .card-header {
        display: flex;
        padding: 8px 24px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        background: #E3E9ED;
        .card-header-title {
            color: #1D2129;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }
    }

    .card-body {
        display: flex;
        padding: 8px;
        gap: 8px;
        .switch-config-item {
            flex: 1;
            margin: 0;
        }
    }
}
`;

type ISettingConfigFormItem = {
    name: string;
    key: string;
    type: string;
    label: string;
}
const SettingConfigFormItem = ({ config }: { config: ISettingConfigFormItem }) => (
    <Form.Item name={config.name} label={config.label} className='switch-config-item'>
        <Form.Item name={[config.name, 'enable']}>
            <Switch />
        </Form.Item>
    </Form.Item>
)


interface ISettingTwoFactorResData {
    updatedDate: string;
    updatedBy: string | null;
    createdDate: string;
    createdBy: string | null;
    id: string;
    shopId: string;
    phone: string;
    email: string;
    enableLogin: boolean;
    enableSalaryReport: boolean;
}

interface ISettingTwoFactorFormValues {
    id: string;
    enableLogin: boolean;
    enableSalaryReport: boolean;
    phone: string;
    email: string;
}

const VERIFY_2_FACTOR = () => {
    const { id = '' } = useParams();
    const dispatch = useDispatch();
    const [loading, setLoadingForm] = useState(false);
    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState<ISettingTwoFactorResData | null>(null);

    const initData = async () => {
        try {
            dispatch(setLoading(true));
            const res = await accountApis.getSettingTwoFactor(id);
            const data = res?.data?.data as ISettingTwoFactorResData;
            if (data) {
                form.setFieldsValue(data);
                setInitialValues(data);
            } else {
                message.error({ content: res.data.message as string, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: 'System error: ' + error.message as string, duration: 3 });
        } finally {
            dispatch(setLoading(false));
        }
    };
    useEffect(() => {
        initData();
    }, []);

    const onReset = () => form.setFieldsValue(initialValues);

    const handleFinish = async (values: any) => {
        const data = values as ISettingTwoFactorFormValues;
        const body: IApiUpdateSettingTwoFactorBody = {
            id: data.id,
            email: data.email,
            enableLogin: data.enableLogin,
            enableSalaryReport: data.enableSalaryReport,
            phone: data.phone,
        };
        setLoadingForm(true);
        try {
            const res = await accountApis.updateSettingTwoFactor(id, body);
            console.log('res.data.data', res.data.data);

            if (res.data.data) {
                await initData();
            } else {
                message.error({ content: res.data.message as string, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: 'System error: ' + error.message as string, duration: 3 });
        } finally {
            setLoadingForm(false);
        }
    };

    return (
        <ContentCreditStyled className='gallery-kit'>
            <Row align={'top'}><h1 style={{ flex: 1 }}>Verify 2-factor</h1>
            </Row>
            <Spin size='large' spinning={loading}>
                <Verify2FactorContainer layout='vertical' form={form} onFinish={handleFinish}>
                    <Form.Item noStyle name={'id'}><input hidden /></Form.Item>
                    <div className="switch-container">
                        <div className="switch-item">
                            <Form.Item noStyle name={'enableLogin'}>
                                <Switch />
                            </Form.Item>
                            Verify OTP when login
                        </div>
                        <div className="switch-item">
                            <Form.Item noStyle name={'enableSalaryReport'}>
                                <Switch />
                            </Form.Item>
                            Verify OTP when viewing employee salary report
                        </div>
                    </div>

                    <div className="card-config">
                        <div className="card-header">
                            <span className="card-header-title">
                                Enter OTP receiving information
                            </span>
                        </div>
                        <div className="card-body">
                            <Form.Item label={'Phone'} name={'phone'} rules={[{ required: true, message: 'Please enter phone' }]}>
                                <PhoneInput placeholder='(000) 000-0000' />
                            </Form.Item>
                            <Form.Item label={'Email'} name={'email'} rules={[{ type: 'email', required: true, message: 'Please enter email' }]}>
                                <Input type='email' placeholder='Please enter...' />
                            </Form.Item>
                        </div>
                    </div>
                    <Form.Item shouldUpdate noStyle>
                        {({ getFieldsValue }) => {
                            return (
                                <Box style={{ textAlign: 'right' }} className='footer-actions'>
                                    <button type='button' className='btn-reset' onClick={onReset}>
                                        <span>Reset</span>
                                    </button>
                                    <button type='button' className='btn-submit' onClick={() => form.submit()}>
                                        <span>Save</span>
                                    </button>
                                </Box>
                            )
                        }}
                    </Form.Item>
                </Verify2FactorContainer>
            </Spin>
        </ContentCreditStyled>
    )
}
const Verify2FactorContainer = styled(Form)`
.btn-submit {
    &:disabled {
        opacity: 0.6;
    }
}
         
.switch-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.switch-item {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 16px;
}
.card-config {
    border-radius: 5px;
    border: 1px solid #DADADA;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 16px;
    .card-header {
        display: flex;
        padding: 8px 24px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        background: #E3E9ED;
        .card-header-title {
            color: #1D2129;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }
    }

    .card-body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 8px;
        gap: 16px;
        input, .ant-input {
            width: 100%;
            border-radius: 4px;
            border: 1px solid #DADADA;
            background: #FFF;
            height: 45px;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}
`
